import React from 'react'


export default class Loading extends React.Component {
    constructor(props){
       super(props)
       this.state = {
          done: undefined
       }
    }
    render(){
       return(
        <div>
            {!this.state.done ? (
                <h1>Loading yo...</h1>
            ) : (
                <h1>hello world</h1>
            )}
        </div>
       )
    }
 }